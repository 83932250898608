.intl-tel-input-block {
  position: relative;
}

.iti-mobile .intl-tel-input.iti-container {
  height: 90vh;
  width: 90vw;
  border: none;
}

.intl-tel-input {
  font-size: 16px;
  color: #000000;
  box-sizing: border-box;

  width: 100%;
  height: 51px;
  border: 1px solid #c8c8c8;
}

.intl-tel-input-block.input-block-error .intl-tel-input {
  border: 1px solid #b00000;
}

.intl-tel-input .form-control {
  width: 100%;
  height: 100%;
  border: none;
  padding: 21px 0 7px 13px;
  outline: none;
  letter-spacing: 0;
  text-align: left;
}

.intl-tel-input input {
  font-size: 16px;
}

.intl-tel-input .selected-flag {
  padding-top: 15px;
}

.intl-tel-input.allow-dropdown .flag-container:hover .selected-flag {
  background-color: transparent;
}

.placeholder {
  position: absolute;
  top: 0;
  left: 13px;
  color: #7d7d7d;
  display: flex;
  align-items: center;
  font-size: 14px;
  height: 100%;
  transition: transform 0.2s;
  transform-origin: 0% 0%;
  background: none;
  pointer-events: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: calc(133% - 24px);
  transform: scale(0.75) translate(2px, -7px);
}

.error_message {
  margin-left: 4px;
  color: #b00000;
}

.optional {
  margin-left: 4px;
}

.intl-tel-input-block .more_info {
  cursor: pointer;
  position: absolute;
  top: 15px;
  right: 18px;
  width: 20px;
  height: 20px;
}

.intl-tel-input-block .more_info_pop_up {
  position: absolute;
  bottom: 50px;
  right: 0;
  min-width: 220px;
  min-height: 40px;
  padding: 8px;
  background: #ffffff;
  box-shadow: 1px 3px 16px 0 rgba(0, 0, 0, 0.2);
  font-size: 14px;
  color: #000000;
  letter-spacing: 0;
  text-align: left;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.2s;
}

.intl-tel-input-block .more_info_pop_up_showing {
  opacity: 1;
}

.intl-tel-input-block .more_info_pop_up:after {
  content: '';
  display: block;

  position: absolute;
  bottom: -13px;
  right: 19px;
  width: 0;
  height: 0;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-top: 13px solid #ffffff;
}
